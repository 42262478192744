// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_ss d_fp d_bG d_dv";
export var alignLeft = "t_qh d_fp d_bG d_dv";
export var alignDiscCenter = "t_st d_fq d_bD d_dw";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignDiscRight = "t_sv d_fr d_bH d_dx";
export var alignRight = "t_qj d_fr d_bH d_dx";
export var footerContainer = "t_sw d_dW d_bW";
export var footerContainerFull = "t_sx d_dT d_bW";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_sy d_w";
export var footerDisclaimerWrapper = "t_km d_km d_ck";
export var badgeWrapper = "t_sz d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "t_sB d_bz d_bJ d_bN d_bL";
export var wide = "t_sC d_cy";
export var right = "t_sD d_bK";
export var line = "t_fk d_fl d_cv";
export var badgeDisclaimer = "t_sF d_bC d_bP d_bJ";
export var badgeContainer = "t_sG d_bz d_bH d_bP";
export var badge = "t_sH";
export var padding = "t_sJ d_c7";
export var end = "t_sK d_bH";
export var linkWrapper = "t_sL d_dB";
export var link = "t_mC d_dB";
export var colWrapper = "t_sM d_cx";
export var consent = "t_h d_h d_bC d_bP";
export var disclaimer = "t_sN d_bz d_bJ";
export var media = "t_sP d_bx d_dy";
export var itemRight = "t_sQ";
export var itemLeft = "t_sR";
export var itemCenter = "t_sS";
export var exceptionWeight = "t_qp q_qT";