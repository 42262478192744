// extracted by mini-css-extract-plugin
export var carouselContainer = "D_vf d_w d_H d_bf d_Z";
export var carouselContainerCards = "D_vg D_vf d_w d_H d_bf d_Z";
export var carouselContainerSides = "D_vh d_w d_H d_Z";
export var prevCarouselItem = "D_vj d_w d_H d_0 d_k";
export var prevCarouselItemL = "D_vk D_vj d_w d_H d_0 d_k";
export var moveInFromLeft = "D_vl";
export var prevCarouselItemR = "D_vm D_vj d_w d_H d_0 d_k";
export var moveInFromRight = "D_vn";
export var selectedCarouselItem = "D_vp d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "D_vq D_vp d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "D_vr D_vp d_w d_H d_Z d_bf";
export var nextCarouselItem = "D_vs d_w d_H d_0 d_k";
export var nextCarouselItemL = "D_vt D_vs d_w d_H d_0 d_k";
export var nextCarouselItemR = "D_vv D_vs d_w d_H d_0 d_k";
export var arrowContainer = "D_vw d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "D_vx D_vw d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "D_vy D_vx D_vw d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "D_vz D_vw d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "D_vB";
export var nextArrowContainerHidden = "D_vC D_vz D_vw d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "D_kG d_0";
export var prevArrow = "D_vD D_kG d_0";
export var nextArrow = "D_vF D_kG d_0";
export var carouselIndicatorContainer = "D_vG d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "D_vH d_w d_bz d_bF";
export var carouselText = "D_vJ d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "D_vK D_vJ d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "D_vL d_b7";
export var carouselIndicator = "D_vM D_vL d_b7";
export var carouselIndicatorSelected = "D_vN D_vL d_b7";
export var arrowsContainerTopRight = "D_vP d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "D_vQ d_0 d_bl d_bC";
export var arrowsContainerSides = "D_vR d_0 d_bl d_bC";
export var smallArrowsBase = "D_vS d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "D_vT D_vS d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "D_vV D_vT D_vS d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "D_vW D_vS d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "D_vX D_vW D_vS d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "D_p9";
export var iconSmall = "D_vY";
export var multipleWrapper = "D_vZ d_bC d_bF d_bf";
export var multipleImage = "D_v0 d_bC";
export var sidesPrevContainer = "D_v1 D_vT D_vS d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "D_v2 D_vT D_vS d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";