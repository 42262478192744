// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_t1";
export var lbContainerInner = "C_t2";
export var movingForwards = "C_t3";
export var movingForwardsOther = "C_t4";
export var movingBackwards = "C_t5";
export var movingBackwardsOther = "C_t6";
export var lbImage = "C_t7";
export var lbOtherImage = "C_t8";
export var prevButton = "C_t9";
export var nextButton = "C_vb";
export var closing = "C_vc";
export var appear = "C_vd";